/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 66번째 로그에서는 이수겸(@keniallee) 님을 모시고 로블록스, 기계는 어떻게 생각하는가, 실리콘밸리 개발자 등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.p, null, "이지스퍼블리싱의 후원으로 도서 나눔 이벤트를 진행합니다. 아래 트윗을 리트윗해주시거나, 아래 페이스북 게시글을 공유하고 댓글을 남겨주신 분 중에 5분을 선정해 '기계는 어떻게 생각하는가?'를 보내드립니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/"
  }, "개발자 팟캐스트 stdout.fm")), "\n"), "\n", React.createElement(_components.h2, null, "게임 개발 플랫폼 로블록스(Roblox)"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.roblox.com/"
  }, "Roblox")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/RPG_%EB%A7%8C%EB%93%A4%EA%B8%B0"
  }, "RPG 만들기 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://unity.com/"
  }, "Unity Real-Time Development Platform | 3D, 2D VR & AR Visualizations")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.minecraft.net/ko-kr/"
  }, "공식 사이트 | Minecraft")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.roblox.com/games/606849621/Jailbreak-CYBERTRUCK"
  }, "🚀 Jailbreak CYBERTRUCK - Roblox")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.lua.org/"
  }, "The Programming Language Lua")), "\n"), "\n", React.createElement(_components.h2, null, "닷넷 개발과 프로그래밍 이야기"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.djangoproject.com/"
  }, "The Web framework for perfectionists with deadlines | Django")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%82%AC%ED%8B%B0%EC%95%84_%EB%82%98%EB%8D%B8%EB%9D%BC"
  }, "사티아 나델라 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://dotnet.microsoft.com/download"
  }, "Download .NET (Linux, macOS, and Windows)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mono-project.com/docs/about-mono/"
  }, "About Mono | Mono")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.microsoft.com/en-us/cloud-platform/windows-server"
  }, "Windows Server 2019 | Microsoft")), "\n"), "\n", React.createElement(_components.h2, null, "아이폰 초기의 메신저 앱 개발과 미국 진출"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://nateonweb.nate.com/"
  }, "네이트온")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/IPod_Touch"
  }, "iPod Touch - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/IPhone_3GS"
  }, "iPhone 3GS - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%8B%A4%EC%9D%8C_%EB%A7%88%EC%9D%B4%ED%94%BC%ED%94%8C"
  }, "다음 마이피플 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.google.com/maps/place/Orange+County,+CA,+USA/@33.6394879,-118.3298547,9z/data=!3m1!4b1!4m5!3m4!1s0x80dc925c54d5f7cf:0xdea6c3618ff0d607!8m2!3d33.7174708!4d-117.8311428"
  }, "Orange County - Google Maps")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.easyspub.co.kr/"
  }, "이지스퍼블리싱")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=221773962"
  }, "알라딘: 기계는 어떻게 생각하는가?")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.seangerrish.com/"
  }, "Sean Gerrish")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://fi.google.com/about/"
  }, "Google Fi")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Kevin_Scott_%28computer_scientist%29"
  }, "Kevin Scott (computer scientist) - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://hogangnono.com/"
  }, "호갱노노 - 아파트 실거래가 1등 앱")), "\n"), "\n", React.createElement(_components.h2, null, "기계는 어떻게 생각하는가?(숀 게리시)"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%B0%A9%EC%9C%84%EA%B3%A0%EB%93%B1%EC%97%B0%EA%B5%AC%EA%B3%84%ED%9A%8D%EA%B5%AD"
  }, "방위고등연구계획국 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/DARPA_%EA%B7%B8%EB%9E%9C%EB%93%9C_%EC%B1%8C%EB%A6%B0%EC%A7%80"
  }, "DARPA 그랜드 챌린지 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Netflix_Prize"
  }, "Netflix Prize - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%8D%B0%EB%AF%B8%EC%8A%A4_%ED%97%88%EC%82%AC%EB%B9%84%EC%8A%A4"
  }, "데미스 허사비스 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.jeopardy.com/"
  }, "Jeopardy! Official Site | Jeopardy.com")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ibm.com/watson/kr-ko/"
  }, "IBM Watson - 한국")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
